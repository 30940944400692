import * as React from "react";
import styled from "styled-components";
import { S3BucketPrefix } from "../../shared-code";

interface ElementProps {
  size: number;
}

const IconContainer = styled.div<ElementProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => `${props.size}px`};
  width: ${(props) => `${props.size * 1.375}px`};
  position: relative;
`;

const RoleImg = styled.img<ElementProps>`
  height: ${(props) => `${props.size}px`};
  position: absolute;
  display: block;
  margin: auto;
`;

const PlaneImg = styled.img<ElementProps>`
  height: ${(props) => `${props.size}px`};
  position: absolute;
  display: block;
  margin: auto;
`;

interface PlaneIconProps {
  size: number;
  planeIcon: string;
  roleIcon: string;
}

export default (props: PlaneIconProps) => {
  return (
    <IconContainer size={props.size}>
      <RoleImg
        size={props.size}
        src={S3BucketPrefix + "role-icons/" + props.roleIcon}
        alt=""
      />
      <PlaneImg
        size={props.size}
        src={S3BucketPrefix + "plane-icons/" + props.planeIcon}
        alt=""
      />
    </IconContainer>
  );
};
