import React from "react";
import styled from "styled-components";
import {
  S3BucketPrefix,
  ScreenSizes,
  getAircraftDataFromFamily,
} from "../../shared-code";
import emptyIcon from "../../images/empty-ability-or-mod.png";
import PlaneIcon from "./PlaneIcon";
import { Plane } from "../DataModels";

const PlaneNameContainer = styled.div`
  display: flex;
  height: 55px;
  gap: 10px;
  width: 305px;
  padding-left: 10px;
  background-color: rgba(255, 255, 255, 0.05);
  margin-right: 1px;
  align-items: center;
  position: sticky;
  left: 0;
  z-index: 2;

  @media (max-width: ${ScreenSizes.tablet}) {
    width: 240px;
    background-color: rgba(0, 0, 0, 1);
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    width: 175px;
  }
`;

interface PlaneNameProps {
  nameLength: number;
}

const PlaneNameInnerContainer = styled.div<PlaneNameProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
  font-size: ${(props) =>
    props.nameLength > 16 ? "12px" : props.nameLength >= 13 ? "15px" : "18px"};
`;

const CombinedNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;

  @media (max-width: ${ScreenSizes.mobile}) {
    flex-direction: column;
  }
`;
const PlaneNameSpan = styled.span`
  font-weight: 600;
  padding-right: 3px;
`;

const PlaneDesignationSpan = styled.span`
  font-weight: 400;
  text-transform: uppercase;
`;

const RoleDiv = styled.div`
  text-transform: uppercase;
  font-size: 15px;

  @media (max-width: ${ScreenSizes.mobile}) {
    display: none;
  }
`;

const StatCell = styled.div`
  width: 100%;
  min-width: 80px;
  font-size: 18px;
  font-weight: 400;
  background-color: rgba(255, 255, 255, 0.05);

  & div {
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const LevelSpan = styled.span`
  height: 40px;
  line-height: 40px;
  aspect-ratio: 1 / 1;
  text-align: center;
  border: 1px white solid;
  border-radius: 50%;
  font-size: 17px;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.05);

  & div {
    display: flex;
    justify-content: center;
  }
`;

const TrophyCell = styled.div`
  width: 100%;
  min-width: 80px;
  background-color: rgba(255, 255, 255, 0.05);

  & div {
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      height: 16px;
      padding-left: 2px;
    }
  }
`;

const ModCell = styled.div`
  width: 100%;
  min-width: 80px;
  background-color: rgba(255, 255, 255, 0.05);

  & div {
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
`;

const ModIcon = styled.img`
  height: 40px;
`;

export interface abilityOrMod {
  name: string;
  level: number;
}

export interface PlaneStatRowProps {
  planeFamily: string;
  trophies: number;
  matches: number;
  winRate: number;
  planeLevel: number;
  ability: abilityOrMod | undefined;
  mods: abilityOrMod[] | undefined;
}

export default (props: Plane) => {
  const plane = getAircraftDataFromFamily(props.planeFamily);
  if (plane) {
    const equippedMods = props.equippedModsBySlotIndex;
    const shouldNameBeSmall = plane.designation.length + plane.name.length;
    const matchesPlayed = props.matchesPlayed || 0;
    const matchesWon = props.matchesWon || 0;
    const winRate = Math.round((matchesWon * 100) / Math.max(matchesPlayed, 1));
    return (
      <>
        <PlaneNameContainer>
          <PlaneIcon
            size={40}
            planeIcon={plane.icon}
            roleIcon={plane.role.roleIcon}
          />
          <PlaneNameInnerContainer nameLength={shouldNameBeSmall}>
            <CombinedNameContainer>
              <PlaneNameSpan>{plane.name}</PlaneNameSpan>
              <PlaneDesignationSpan>{plane.designation}</PlaneDesignationSpan>
            </CombinedNameContainer>
            <RoleDiv>{plane.role.roleName}</RoleDiv>
          </PlaneNameInnerContainer>
        </PlaneNameContainer>
        <TrophyCell>
          <div>
            {props.trophyCount}{" "}
            <img src={S3BucketPrefix + "trophy.png"} alt="" />
          </div>
        </TrophyCell>
        <StatCell>
          <div>{matchesPlayed}</div>
        </StatCell>
        <StatCell>
          <div>{Math.round(winRate)}%</div>
        </StatCell>
        <StatCell>
          <div>
            <LevelSpan>{props.planeLevel}</LevelSpan>
          </div>
        </StatCell>
        <StatCell>
          <div>
            <ModIcon
              src={
                props.equippedActiveAbility
                  ? S3BucketPrefix + "ability-icons/" + plane.ability + ".png"
                  : emptyIcon
              }
              alt=""
            />
          </div>
        </StatCell>
        <ModCell>
          <div>
            {equippedMods[0].name != undefined && equippedMods[0].name != "" ? (
              <ModIcon
                key={`mod-1`}
                src={
                  S3BucketPrefix + "mod-icons/" + equippedMods[0].name + ".png"
                }
              />
            ) : (
              <ModIcon key={`mod-1`} src={emptyIcon} />
            )}
            {equippedMods[1].name != undefined && equippedMods[1].name != "" ? (
              <ModIcon
                key={`mod-2`}
                src={
                  S3BucketPrefix + "mod-icons/" + equippedMods[1].name + ".png"
                }
              />
            ) : (
              <ModIcon key={`mod-2`} src={emptyIcon} />
            )}
          </div>
        </ModCell>
      </>
    );
  }
  return null;
};
