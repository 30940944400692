import * as React from "react";
import styled from "styled-components";
import { aircraftData } from "../../planeData";
import PlaneIcon from "./PlaneIcon";
import { ScreenSizes, isSmallScreen, screenWidth } from "../../shared-code";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 22px 0 12px 0;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  justify-content: center;
  align-items: center;
  color: white;
  text-transform: uppercase;
  width: 100%;

  @media (max-width: ${ScreenSizes.narrow}) {
    flex-direction: row;
    justify-content: space-between;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(255, 255, 255, 0.1) 100%
    );
  }
`;

const PlaneName = styled.div`
  display: flex;
  flex-direction: ${isSmallScreen() ? "column" : "row"};
  gap: ${isSmallScreen() ? "6px" : "10px"};
  font-size: 27px;
  font-weight: 600;
  align-items: center;

  @media (max-width: ${ScreenSizes.narrow}) {
    font-size: 18px;
    align-items: flex-start;
  }
`;

const Designation = styled.span`
  font-weight: 400;
`;

const IconAndName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @media (max-width: ${ScreenSizes.narrow}) {
    flex-direction: row;
    justify-content: flex-start;
    gap: 2px;
  }
`;

const Stat = styled.div`
  font-size: 50px;
  font-weight: 600;

  & img {
    height: 35px;
  }

  @media (max-width: ${ScreenSizes.narrow}) {
    font-size: 30px;

    & img {
      height: 20px;
    }
  }
`;

const DescriptionDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  @media (max-width: ${ScreenSizes.narrow}) {
    align-items: flex-end;
  }
`;

const Description = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.6px;

  @media (max-width: ${ScreenSizes.narrow}) {
    font-size: 12px;
    letter-spacing: 1.2px;
  }
`;

const SubDescription = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1.6px;
  text-align: center;

  @media (max-width: ${ScreenSizes.narrow}) {
    font-size: 12px;
    letter-spacing: 1.2px;
  }
`;

const StatDiv = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  @media (max-width: ${ScreenSizes.narrow}) {
    flex-direction: column-reverse;
    gap: 5px;
    text-align: right;
    padding-right: 10px;
  }
`;

interface TopAircraftCardProps {
  plane: aircraftData;
  statDescription: string;
  subDescription?: string | null;
  children: React.ReactNode;
}

export default (props: TopAircraftCardProps) => {
  const [isNarrowScreen, setIsNarrowScreen] = React.useState(
    screenWidth <= 500
  );

  return (
    <Wrapper>
      <IconAndName>
        <PlaneIcon
          size={isNarrowScreen ? 40 : 90}
          planeIcon={props.plane.icon}
          roleIcon={props.plane.role.roleIcon}
        />
        <PlaneName>
          {props.plane.name}
          <Designation>{props.plane.designation}</Designation>
        </PlaneName>
      </IconAndName>
      <StatDiv>
        <Stat>{props.children}</Stat>
        <DescriptionDiv>
          <Description>{props.statDescription}</Description>
          <SubDescription>{props.subDescription}</SubDescription>
        </DescriptionDiv>
      </StatDiv>
    </Wrapper>
  );
};
