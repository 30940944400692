import * as React from "react";
import styled from "styled-components";
import { ScreenSizes } from "../../shared-code";

interface ContentDivProps {
  smallDevice: boolean;
  hasSubHeader: boolean;
  children: React.ReactNode;
  fullScreen: boolean;
}

const ContentDiv = styled.div<ContentDivProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: ${(props) => (props.fullScreen ? "100%" : "1440px")};
  height: 100%;
  justify-content: flex-start;
  padding-top: ${(props) => (props.hasSubHeader ? "143px" : "83px")};
  position: relative;
  margin-inline: auto;
  padding-inline: 25px;
  padding-bottom: 80px;

  @media (max-width: ${ScreenSizes.narrow}) {
    padding-inline: 15px;
    padding-bottom: 80px;
  }
`;

export default (props: ContentDivProps) => {
  return <ContentDiv {...props}>{props.children}</ContentDiv>;
};
