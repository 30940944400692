import React from "react";
import styled from "styled-components";
import PlaneStatRow from "./PlaneStatRow";
import { Plane } from "../DataModels";
import { ScreenSizes } from "../../shared-code";

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 305px 1fr 1fr 1fr 1fr 1fr 1fr;
  position: relative;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.5);
  overflow-x: auto;
  gap: 1px;

  @media (max-width: ${ScreenSizes.tablet}) {
    grid-template-columns: 240px 1fr 1fr 1fr 1fr 1fr 1fr;
    padding-inline: 0;
    border: 0px;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    grid-template-columns: 175px 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`;

const HeaderCell = styled.div`
  color: white;
  font-size: 14px;
  background-color: hsla(0, 0%, 0%, 1);
  position: sticky;
  top: 0px;
  margin-inline: auto;
  height: 30px;
  line-height: 30px;
  text-align: center;
  text-transform: uppercase;
  border-bottom: 1px solid #9e9e9e;
  width: 100%;
  min-width: 80px;
  z-index: 2;
`;

const AircraftContainer = styled(HeaderCell)`
  left: 0;
  width: 305px;
  text-align: left;
  padding-left: 20px;
  z-index: 3;

  @media (max-width: ${ScreenSizes.tablet}) {
    width: 240px;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    width: 175px;
  }
`;

interface StatsContainerProps {
  rows: Plane[];
}

export default (props: StatsContainerProps) => {
  return (
    <Wrapper>
      <>
        <AircraftContainer>Aircraft</AircraftContainer>
        <HeaderCell>Trophies</HeaderCell>
        <HeaderCell>Matches</HeaderCell>
        <HeaderCell>W/L</HeaderCell>
        <HeaderCell>Level</HeaderCell>
        <HeaderCell>Ability</HeaderCell>
        <HeaderCell>Mods</HeaderCell>
      </>
      {props.rows.map((row, index) => (
        <PlaneStatRow key={`statRow-${index}`} {...row} />
      ))}
    </Wrapper>
  );
};
